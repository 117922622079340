import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box } from '@westhechiang/box';
import { Flex } from '@westhechiang/flex';
import { P } from '../../../../../../P';
import { RoundedImg } from '../../../../../../RoundedImg';
import { RoundedBox } from '../../../../../../RoundedBox';
import { Heading } from '../../../../../../Headings';
// @ts-ignore
import data from './assets/data.json';
import { Span } from '../../../../../../Span/Span';

export const AboutUsProfile = ({ name }) => {
  const { wendyImage, jordanImage } = useStaticQuery(graphql`
    query AboutUsProfileQuery {
      wendyImage: file(relativePath: { eq: "wendy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jordanImage: file(relativePath: { eq: "jordan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const map = {
    wendy: {
      image: wendyImage,
      containerFlexProps: {
        flexDirection: ['column', 'column', 'row'],
      },
      floatingBorderProps: {
        bottom: [-16],
        right: [-25],
      },
      nameContainerProps: {
        bottom: [50],
      },
      bio: data[name].bio,
      pronouns: data[name].pronouns,
    },
    jordan: {
      image: jordanImage,
      containerFlexProps: {
        flexDirection: ['column', 'column', 'row-reverse'],
      },
      floatingBorderProps: {
        bottom: [-16],
        left: [-25],
      },
      nameContainerProps: {
        top: [30],
      },
      bio: data[name].bio,
      pronouns: data[name].pronouns,
    },
  };
  return (
    <Flex
      width={1}
      mb={5}
      alignItems="center"
      border={'4px solid'}
      bg="pink"
      borderColor="mustard"
      position="relative"
      {...map[name].containerFlexProps}
    >
      <Box
        width={1}
        height={'100%'}
        zIndex={1}
        border={'4px solid'}
        borderColor="mustard"
        position="absolute"
        {...map[name].floatingBorderProps}
      />
      <RoundedBox
        width={[1, 1, 800]}
        position="relative"
        borderColor="mustard"
        borderRight={'4px solid'}
      >
        <RoundedImg fluid={map[name].image.childImageSharp.fluid} />
        <Box
          position="absolute"
          left="50%"
          transform="translateX(-50%)"
          {...map[name].nameContainerProps}
        >
          <Heading
            fontFamily="logo"
            fontSize={75}
            lineHeight="0.4"
            textAlign="center"
            color="magenta"
            textTransform="capitalize"
          >
            {name} <Span fontSize={20}>{`(${map[name].pronouns})`}</Span>
          </Heading>
        </Box>
      </RoundedBox>
      <Flex width={[1, 1, 1]} flexDirection="column" px={5} pt={3} zIndex={1}>
        {map[name].bio.map((paragraph: string) => (
          <P mb={3} key={paragraph} fontSize={[14, 5]}>
            {paragraph}
          </P>
        ))}
      </Flex>
    </Flex>
  );
};
