import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import { AboutUs } from '../components/Pages/AboutUs/components/AboutUs';

export const AboutUsPage = () => {
  return (
    <Layout>
      <AboutUs />
    </Layout>
  );
};

export default AboutUsPage;

export const pageQuery = graphql`
  query AboutUsPageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
