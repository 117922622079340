import React from 'react';
import { Flex } from '@westhechiang/flex';
import { Page } from '../../../../Page';
import { Heading } from '../../../../Headings';
import { AboutUsBottom } from './components/AboutUsBottom';
import { AboutUsProfile } from './components/AboutUsProfile';

export const AboutUs = () => (
  <Page>
    {/* Outer content */}
    <Flex justifyContent="center" width={[1]} maxWidth={1440}>
      {/* Inner content */}
      <Flex width={[1]} flexDirection="column">
        {/* Center Column - All */}
        <Flex
          width={1}
          mt={[5, 150, 148]}
          alignItems="center"
          flexDirection="column"
        >
          <Heading fontFamily="logo" fontSize={12} color="darkGray">
            About Us
          </Heading>

          {/* Text Content */}
          <Flex
            flexDirection="column"
            mt={4}
            px={[0, 4]}
            width={[1]}
            alignItems="center"
          >
            {/* Wendy */}
            <AboutUsProfile name="wendy" />
            {/* Jordan */}
            <AboutUsProfile name="jordan" />
            <AboutUsBottom />
          </Flex>
          {/* End text content */}
        </Flex>
        {/* End center content */}
      </Flex>
    </Flex>
  </Page>
);
