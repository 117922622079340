import * as React from 'react';
import { Box } from '@westhechiang/box';
import styled from 'styled-components';
import { style } from 'styled-system';

const borderTopLeftRadius = style({
  prop: 'borderTopLeftRadius',
  cssProperty: 'borderTopLeftRadius',
});
const borderTopRightRadius = style({
  prop: 'borderTopRightRadius',
  cssProperty: 'borderTopRightRadius',
});
const borderBottomLeftRadius = style({
  prop: 'borderBottomLeftRadius',
  cssProperty: 'borderBottomLeftRadius',
});
const borderBottomRightRadius = style({
  prop: 'borderBottomRightRadius',
  cssProperty: 'borderBottomRightRadius',
});

export const RoundedBox = styled(Box)`
  ${borderTopLeftRadius}
  ${borderTopRightRadius}
  ${borderBottomLeftRadius}
  ${borderBottomRightRadius}
`;
