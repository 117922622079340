import * as React from 'react';
import { Flex } from '@westhechiang/flex';
import { P } from '../../../../../../P';

export const AboutUsBottom = () => (
  <Flex width={1} px={3}>
    <P mb="36px">
      *We do this work from a place of privilege within our society and
      recognize this industry has been built on the backs of marginalized
      communities targeted by the war on drugs. Currently in the US, people are
      making millions of dollars and creating businesses selling the same plant
      that still holds thousands of people in prison. Our perspectives and work
      reflects this injustice and seeks to help repair the damage done to
      impacted communities.*
    </P>
  </Flex>
);
